import './styles.scss';

/**
 * The external dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FromField = ({
  hasError,
  errorMessage,
  className,
  ...props
}) => (
  <div className={classNames('form-control', className)}>
    <input className="field" {...props} />

    {hasError && <span className="form-control__message">{errorMessage}</span>}
  </div>
);

/**
* Define the interface of the component.
*
* @type {Object}
*/
FromField.protoTypes = {
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string
};

export default FromField;
