import './styles.scss';

/**
 * The external dependencies.
 */
import React from 'react';

const Loader = () => (
  <div className="loader-wrapper">
    <div className="loader">
      <div className="loader-ellips">
        <span className="loader-ellips__dot"></span>
        <span className="loader-ellips__dot"></span>
        <span className="loader-ellips__dot"></span>
        <span className="loader-ellips__dot"></span>
      </div>
    </div>
  </div>
);

export default Loader;
