import './styles.scss';

/**
 * The external dependencies.
 */
import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * The internal dependencies.
 */
import LoaderSmall from 'components/common/loader-small';

const Dropdown = ({
  children,
  className,
  btnClasses,
  renderHead,
  asSelect,
  options,
  onChange,
  loading
}) => {
  /**
   * The active state of the dropdown
   *
   * @type {Boolean}
   */
  const [isActive, setIsActive] = useState(false);

  /**
   * The active option.
   */
  const [activeOption, setActiveOption] = useState(options[0] || 'Select ...');

  /**
   * The dropdown node element.
   *
   * @type {HTML Element}
   */
  const dropdownMenuRef = useRef(null);

  /**
   * Handle the dropdown button click.
   *
   * @param  {Object} event The event
   * @return {Void}
   */
  const handleButtonClick = event => {
    event.preventDefault();

    setIsActive(state => !state);
  }

  /**
   * Handle the document click.
   *
   * @param  {Object} event The event
   * @return {Void}
   */
  const handleDocumentClick = event => {
    const el = dropdownMenuRef.current;
    const target = event.target;

    if ( (el !== target) && !el.contains(target) ) {
      setIsActive(false);
    }
  }

  /**
   * Handle the option click.
   *
   * @param  {Object}  option  The option
   * @return {Void}
   */
  const handleOptionClick = option => event => {
    event.preventDefault();

    setActiveOption(option);
    setIsActive(false);
  }

  /**
   * Attach onChange event.
   */
  useEffect(() => {
    onChange(activeOption);
  }, [activeOption]);

  /**
   * Set initial option.
   */
  useEffect(() => {
    if (asSelect && !loading && options) {
      setActiveOption(options[0]);
    }
  }, [loading, options]);

  /**
   * Closes the dropdown on document click.
   *
   * @return {Void}
   */
  useEffect(() => {
    window.addEventListener('click', handleDocumentClick);

    return () => window.removeEventListener('click', handleDocumentClick);
  }, []);

  return (
    <div
      ref={dropdownMenuRef}
      className={classNames({
        'dropdown': true,
        'dropdown--select': asSelect,
        'open': isActive
      }, className)}
    >
      <div className="dropdown__head">
        <button
          onClick={handleButtonClick}
          type="button"
          className={classNames('dropdown__btn', btnClasses)}
        >
          {
            asSelect
            ? (
              <Fragment>
                {
                  loading
                  ? (
                    <span>
                      <LoaderSmall />
                    </span>
                  ) : <span>{activeOption.value}</span>
                }

                <i className="material-icons">arrow_drop_down</i>
              </Fragment>
            ) : renderHead()
          }
        </button>
      </div>

      <div
        className={classNames({
          'dropdown__body': true,
          'active': isActive
        })}
      >
        {
          asSelect
          ? (
            <Fragment>
              {
                loading
                ? <LoaderSmall />
                : (
                  <ul className="dropdown__list">
                    {options.map(option => (
                      <li
                        className="dropdown__list-item"
                        key={option.key}
                      >
                        <button
                          onClick={handleOptionClick(option)}
                          className="dropdown__list-btn"
                        >{option.value}</button>
                      </li>
                    ))}
                  </ul>
                )
              }
            </Fragment>
          ) : children
        }
      </div>
    </div>
  );
};

/**
 * Define the interface of the component.
 *
 * @type {Object}
 */
Dropdown.propTypes = {
  asSelect: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array,
  loading: PropTypes.bool,
  className: PropTypes.string,
  btnClasses: PropTypes.string,
  renderHead: PropTypes.func.isRequired,
};

/**
 * Define the deafult props of the component.
 *
 * @type {Object}
 */
Dropdown.defaultProps = {
  asSelect: false,
  className: '',
  btnClasses: '',
  renderHead: () => {},
  onChange: () => {},
  options: [],
  loading: false
};

export default Dropdown;