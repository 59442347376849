import './styles.scss';

/**
 * The external dependencies.
 */
import React from 'react'
import classNames from 'classnames'
import {Link} from 'react-router-dom'

const Logo = ({ className }) => (
  <Link
    to="/"
    className={classNames('logo', className)}
  >
    AudienceEye
  </Link>
);

export default Logo;
