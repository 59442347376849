/**
 * The external dependencies.
 */
import React from 'react';
import {Link} from 'react-router-dom';
import {pathOr, isEmpty} from 'ramda';

/**
 * The internal dependencies.
 */
import {useGlobalState, useStripeCustomer} from 'statecontext/hooks';
import SubscriptionCard from 'components/profile-view/subscription-card';
import ProfileCurrentPlan from 'components/profile-view/current-plan';
import LoaderSingle from 'components/common/loader-single';
import Alert from 'components/common/alert';
import LinkSocialAccount from 'features/link-social-account';

const Profile = () => {
  const {getters: {stripeCustomerId}} = useGlobalState('auth');
  const { data, status, error } = useStripeCustomer(stripeCustomerId);
  const hasSubs = !isEmpty(pathOr(false, ['subscriptions', 'data'], data) || []);

  return (
    <section className="section-profile">
      <div className="container container--small">
        <div className="section__group">
          <h1 className="section__title">Facebook Account</h1>

          <LinkSocialAccount />
        </div>

        <div className="section__group">
          <h1 className="section__title">Subscription Details</h1>

          {status === 'loading' ? (
            <div className="cols cols--justify-center">
              <div className="col col--1of2">
                <div className="box">
                  <LoaderSingle />
                </div>
              </div>
            </div>
          ):(
            <React.Fragment>
              {error ? (
                <div className="cols cols--justify-center">
                  <div className="col col--1of2">
                    <div className="box box--simple">
                      <Alert variant="danger">Something went wrong. Try again later.</Alert>
                    </div>
                  </div>
                </div>
              ):(
                <React.Fragment>
                  {
                    !hasSubs
                    ? (
                      <div className="cols cols--justify-center">
                        <div className="col col--1of2">
                          <div className="box box--centered">
                            <h3 className="box__title">You don't have any active subscriptions.</h3>

                            <Link className="btn btn--primary btn--rounded" to="/subscriptions">Subscribe Now</Link>
                          </div>
                        </div>
                      </div>
                    ):(
                      <div className="cols cols--justify-center">
                        <div className="col col--1of2">
                          {data.subscriptions.data[0].status === 'active' && (
                            <div className="box">
                              <ProfileCurrentPlan
                                subId={data.subscriptions.data[0].id}
                                planId={data.subscriptions.data[0].plan.id}
                                nextInvoiceDate={data.subscriptions.data[0].current_period_end}
                                isCancelAtPeriodEnd={data.subscriptions.data[0].cancel_at_period_end}
                              />
                            </div>
                          )}

                          {data.subscriptions.data[0].status !== 'active' && (
                            <div className="box box--simple">
                              {data.subscriptions.data[0].status === 'incomplete_expired' && (
                                <Alert variant="danger">Your subscription status is incomplete and expired.</Alert>
                              )}

                              {data.subscriptions.data[0].status === 'incomplete' && (
                                <Alert variant="danger">
                                  <p>Payment failed.</p>
                                  <p>Your subscription status is incomplete.</p>
                                  <p>Please update your card details!</p>
                                </Alert>
                              )}

                              {data.subscriptions.data[0].status === 'past_due' && (
                                <Alert variant="danger">Payment with your card failed. Please update your payment method or the subscription will be canceled soon.</Alert>
                              )}

                              {data.subscriptions.data[0].status === 'canceled' && (
                                <Alert variant="danger">Your subscription is canceled.</Alert>
                              )}

                              {data.subscriptions.data[0].status === 'unpaid' && (
                                <Alert variant="danger">Your subscription is unpaid.</Alert>
                              )}
                            </div>
                          )}

                          <div className="box box--small-spacing">
                            <SubscriptionCard
                              customerId={stripeCustomerId}
                              subscription={data.subscriptions.data[0]}
                              paymentId={data.subscriptions.data[0].default_payment_method}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  }
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </section>
  );
};

export default Profile;