import './styles.scss';

/**
 * The external dependencies.
 */
import React from 'react';

const LoaderSimple = () => (
  <div className="lds-ring">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
);

export default LoaderSimple;
