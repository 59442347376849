import './styles.scss';

/**
 * The external dependencies.
 */
import React from 'react';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUserCircle} from '@fortawesome/free-solid-svg-icons';

/**
 * The internal dependencies.
 */
import {useGlobalState} from 'statecontext/hooks';
import Logo from 'components/logo';
import Dropdown from 'components/elements/dropdown';
import Navigation from 'components/navigation';

const Header = () => {
  const {services} = useGlobalState('auth');

  const logout = async () => {
    await services.logout();
  }

  return (
    <header className="header">
      <div className="header__inner">
        <div className="header__logo">
          <Logo className="logo--medium" />
        </div>

        <aside className="header__aside">
          <Navigation />

          <Dropdown
            className="header__dropdown"
            btnClasses="header__btn"
            renderHead={() => <FontAwesomeIcon icon={faUserCircle} />}
          >
            <ul className="header__list">
              <li>
                <Link to="/">Home</Link>
              </li>

              <li>
                <Link to="/profile">My profile</Link>
              </li>

              <li>
                <Link to="/subscriptions">Plans</Link>
              </li>

              <li>
                <button
                  onClick={logout}
                  type="button"
                >Logout</button>
              </li>
            </ul>
          </Dropdown>
        </aside>
      </div>
    </header>
  );
};

export default Header;
