import './styles.scss';

/**
 * The external dependencies.
 */
import React from 'react';

/**
 * The internal dependencies.
 */
import ActiveLink from 'components/active-link';

const Navigation = () => (
  <nav className="nav">
    <ul>
      {!process.env.REACT_APP_DISABLE_INTEREST_SEARCH &&

        <li>
          <ActiveLink to="/">Interest Search</ActiveLink>
        </li>
      }
      <li>
        <ActiveLink to={process.env.REACT_APP_DISABLE_INTEREST_SEARCH ? '/': "/ad-preview"}>Ad Preview</ActiveLink>
      </li>
    </ul>
  </nav>
);

export default Navigation;