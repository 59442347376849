/**
 * The external dependencies.
 */
import React from 'react';

/**
 * The internal dependencies.
 */
import FromField from 'components/common/form-field';

const FormikField = ({
  type,
  field,
  form: { touched, errors },
  ...props
}) => {
  const fieldProps = {
    ...field,
    ...props
  };
  const hasError = touched[field.name] && errors[field.name];
  const errorMessage = errors[field.name];

  return (
    <FromField
      hasError={hasError}
      errorMessage={errorMessage}
      type={type || 'text'}
      {...fieldProps}
    />
  );
};

export default FormikField;