/**
 * The external dependencies.
 */
import React from 'react';
import {NavLink} from 'react-router-dom';

const ActiveLink = ({ exact = true, ...props}) => (
  <NavLink
    exact={exact}
    activeClassName="current"
    {...props}
  />
);

export default ActiveLink;
