/**
 * The external dependencies.
 */
import React, {Fragment} from 'react';

/**
 * The internal dependencies.
 */
import Header from 'components/header';

const DefaultLayout = ({ children }) => (
  <Fragment>
    <Header />

    <main className="main">
      {children}
    </main>
  </Fragment>
);

export default DefaultLayout;