/**
 * The external dependencies.
 */
import {useEffect, useCallback} from 'react';

/**
 * The internal dependencies.
 */
import {useGlobalState} from 'statecontext/hooks';

let unsubscribe;

/**
 * Hook to handle firebase webhooks listener.
 *
 * @return {Boolean}
 */
export default function useUserWebhooksListener(firebase) {
  const {
    state: {user},
    getters: {isUserLoggedIn},
    services
  } = useGlobalState('auth');
  const {services: paymentStatusServices} = useGlobalState('paymentStatus');

  /**
   * Memoized subscription to the firebase auth.
   *
   * @return {Function}
   */
  const subscribe = useCallback(() => {
    const unsubscribeListener = firebase.database
      .collection('stripe_customers')
      .doc(user.uid)
      .collection('webhooks')
      .doc('invoice.payment')
      .onSnapshot(async (doc) => {
        await services.getUserClaims();

        paymentStatusServices.setPaymentStatus({id: doc.id, ...doc.data()});
      });

    return unsubscribeListener;
  }, []); // eslint-disable-line

  /**
   * Subscribe to the firebase webhooks doc
   * and unsubscribe on component unmount.
   *
   * @return {Function}
   */
  useEffect(() => {
    if (isUserLoggedIn) {
      unsubscribe = subscribe()
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (!isUserLoggedIn && unsubscribe) {
      unsubscribe()
    }
  }, [isUserLoggedIn]) // eslint-disable-line
}