/**
 * The external dependencies.
 */
import React from 'react';

const PaymentCancel = () => (
  <section className="section-message">
    <div className="section__inner">
      <div className="section__icon">
        <i className="material-icons">remove_circle</i>
      </div>

      <h1 className="section__title">Your checkout was canceled</h1>
    </div>
  </section>
);

export default PaymentCancel;