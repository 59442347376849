/**
 * The external dependencies.
 */
import React from 'react'
import { Route } from 'react-router-dom'

/**
 * The internal dependencies.
 */
import DashboardRoute from 'router/wrappers/dashboard-route'
import SubscriberRoute from 'router/wrappers/subscriber-route'
import AuthRoute from 'router/wrappers/auth-route'
import Home from 'views/home'
import Login from 'views/auth/login'
import SignUp from 'views/auth/signup'
import Subscriptions from 'views/subscriptions'
import PaymentSuccess from 'views/payment/success'
import PaymentCancel from 'views/payment/cancel'
import Profile from 'views/profile'
import AdPreview from 'views/ad-preview'

/**
 * The application routes.
 *
 * @type {Array}
 */
export default [
  {
    key: 'home',
    path: process.env.REACT_APP_DISABLE_INTEREST_SEARCH ? '/home' : '/',
    component: Home,
    routeComponent: SubscriberRoute,
    exact: true
  },
  {
    key:  process.env.REACT_APP_DISABLE_INTEREST_SEARCH ? 'home' : 'ad-preview',
    path: process.env.REACT_APP_DISABLE_INTEREST_SEARCH ? '/' : '/ad-preview',
    component: AdPreview,
    routeComponent: SubscriberRoute,
    exact: true
  },
  {
    key: 'login',
    path: '/login',
    component: Login,
    routeComponent: AuthRoute,
    exact: true
  },
  {
    key: 'signup',
    path: '/signup',
    component: SignUp,
    routeComponent: AuthRoute,
    exact: true
  },
  {
    key: 'profile',
    path: '/profile',
    component: Profile,
    routeComponent: DashboardRoute,
    exact: true
  },
  {
    key: 'subscriptions',
    path: '/subscriptions',
    component: Subscriptions,
    routeComponent: DashboardRoute,
    exact: true
  },
  {
    key: 'payment-success',
    path: '/payment/success',
    component: PaymentSuccess,
    routeComponent: DashboardRoute,
    exact: true
  },
  {
    key: 'payment-cancel',
    path: '/payment/cancel',
    component: PaymentCancel,
    routeComponent: DashboardRoute,
    exact: true
  },
  {
    key: 'not-found',
    path: '*',
    routeComponent: Route,
    render: () => <h1>Not Found</h1>
  }
]