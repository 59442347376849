import './styles.scss';

/**
 * The external dependencies.
 */
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const Button = React.forwardRef(
  (
    { prefix, variant, size, rounded, active, className, block, type, as, ...props },
    ref,
  ) => {
    const classes = classNames(
      className,
      prefix,
      active && 'active',
      `${prefix}--${variant}`,
      block && `${prefix}--block`,
      size && `${prefix}-${size}`,
      rounded && `${prefix}--rounded`,
    );

    if (props.href) {
      return (
        <a
          {...props}
          ref={ref}
          className={classNames(classes, props.disabled && 'disabled')}
        >{props.children}</a>
      );
    }

    if (ref) {
      props.ref = ref;
    }

    if (!as) {
      props.type = type;
    }

    const Component = as || 'button';

    return <Component {...props} className={classes} />;
  }
);

/**
 * Define the display name of the component.
 *
 * @type {String}
 */
Button.displayName = 'Button';

/**
* Define the interface of the component.
*
* @type {Object}
*/
Button.propTypes = {
  /**
   * @default 'btn'
   */
  prefix: PropTypes.string,

  /**
   * One or more button variant combinations
   *
   * buttons may be one of a variety of visual variants such as:
   *
   * `'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark', 'light', 'link'`
   *
   * as well as "outline" versions (prefixed by 'outline-*')
   *
   * `'outline-primary', 'outline-secondary', 'outline-success', 'outline-danger', 'outline-warning', 'outline-info', 'outline-dark', 'outline-light'`
   */
  variant: PropTypes.string,

  /**
   * Specifies a large or small button.
   *
   * @type ('sm'|'lg')
   */
  size: PropTypes.string,

  /** Adds rounded corners */
  rounded: PropTypes.bool,

  /** Spans the full width of the Button parent */
  block: PropTypes.bool,

  /** Manually set the visual state of the button to `:active` */
  active: PropTypes.bool,

  /**
   * Disables the Button, preventing mouse events,
   * even if the underlying component is an `<a>` element
   */
  disabled: PropTypes.bool,

  /** Providing a `href` will render an `<a>` element, _styled_ as a button. */
  href: PropTypes.string,

  /**
   * Defines HTML button type attribute.
   *
   * @default 'button'
   */
  type: PropTypes.oneOf(['button', 'reset', 'submit', null]),

  as: PropTypes.elementType
};

/**
* Define the default props of the component.
*
* @type {Object}
*/
Button.defaultProps = {
  prefix: 'btn',
  variant: 'primary',
  active: false,
  disabled: false,
  type: 'button'
};

export default Button;
