/**
 * The external dependencies.
 */
import { useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';

/**
 * The internal dependencies.
 */
import Api from 'lib/api';
import { useGlobalState } from 'statecontext/hooks';
import stripeConfigs from 'lib/stripe-configs';

/**
 * Hook to handle stripe session redirect.
 *
 * @param  {Object} The options
 * @return {Object}
 */
export default function useStripeSession(
  firebase,
  {
    method = 'createStripeSession',
    customer_id,
    success_url = stripeConfigs.checkout_success_url,
    cancel_url = stripeConfigs.checkout_cancel_url,
    ...options
  }
) {
  const {state: { user }} = useGlobalState('auth');
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  /**
   * Trigger a stripe session creation.
   *
   * @param  {String} id The customer id
   * @return {Void}
   */
  const redirectUserToStripeCheckout = (id) => {
    firebase[method]({
      user_id: user.uid,
      customer_id: id,
      success_url,
      cancel_url,
      ...options
    }).then((res) => {
      stripe.redirectToCheckout({
        sessionId: res.data.id
      }).then((result) => {
        if (result.error.message) {
          setError(result.error.message)
        }

        setLoading(false)
      })
    }).catch((error) => {
      setLoading(false)
      setError('Failed to start checkout session')
    })
  }

  /**
   * Start the stripe session creation.
   *
   * @return {Void}
   */
  const startStripeCheckout = async () => {
    setError(null)
    setLoading(true)

    if (!customer_id) {
      await Api.createStripeCustomer({
        uid: user.uid,
        email: user.email
      })

      const { claims } = await firebase.auth.currentUser.getIdTokenResult(true)

      redirectUserToStripeCheckout(claims.stripe_customer_id)
    } else {
      redirectUserToStripeCheckout(customer_id)
    }
  }

  const resetSessionState = event => {
    if (event && event.preventDefault) {
      event.preventDefault()
    }

    setError(null)
    setLoading(false)
  }

  return {
    sessionLoading: loading,
    sessionError: error,
    startStripeCheckout,
    resetSessionState
  }
}
