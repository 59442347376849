import './styles.scss';

/**
 * The external dependencies.
 */
import React from 'react';

/**
 * The internal dependencies.
 */
import interestsTableBuilder from 'lib/helpers/interests-table-builder';
import TableDefault from 'components/tables/default';

const TableInterests = ({ searchInfo }) => {
  const searchLoading = searchInfo.status === 'loading';
  const tableBody = interestsTableBuilder(searchInfo.data || []);

  return (
    <React.Fragment>
      <TableDefault
        isLoading={searchLoading}
        error={searchInfo.error?.message}
        head={[
          {
            key: 'interest-name',
            content: 'Interest Name'
          },
          {
            key: 'audience-size',
            content: 'Audience Size'
          },
          {
            key: 'topic',
            content: 'Topic'
          },
          {
            key: 'path',
            content: 'Path'
          }
        ]}
        body={tableBody}
      />
    </React.Fragment>
  );
};

export default TableInterests;
