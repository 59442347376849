/**
 * The external dependencies.
 */
import React from 'react';
import {Link} from 'react-router-dom';

const PaymentSuccess = () => (
  <section className="section-message">
    <div className="section__inner">
      <div className="section__icon">
        <i className="material-icons">check_circle</i>
      </div>

      <h1 className="section__title">Your checkout was successful</h1>

      <Link to="/" className="btn btn--primary btn--rounded">Start Now</Link>
    </div>
  </section>
);

export default PaymentSuccess;