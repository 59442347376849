/**
 * The external dependencies.
 */
import * as yup from 'yup'

/**
 * Creates a schema for search form validation.
 *
 * @return {Object}
 */
export default () => {
  const search = yup
    .string()
    .required('Search for interest field is requred!')

  return yup
    .object()
    .shape({ search })
}