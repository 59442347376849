import './styles.scss';

/**
 * The external dependencies.
 */
import React from 'react';
import classNames from 'classnames';

const LoaderSingle = ({ size }) => (
  <div className={classNames('loader-single', size)}>Loading...</div>
);

export default LoaderSingle;
