/**
 * The external dependencies.
 */
import React from 'react';

/**
 * The internal dependencies.
 */
import FormLogin from 'components/forms/login';

const Login = () => (
  <section className="section section--auth">
    <FormLogin />
  </section>
);

export default Login;