/**
 * The external dependencies.
 */
import {createContext} from 'react';
import combineReducers from 'react-combine-reducers';

/**
 * The internal dependencies.
 */
import authReducer from 'statecontext/auth/reducer';
import paymentStatusReducer from 'statecontext/payment-status/reducer';

const [rootReducer, initialState] = combineReducers({
  auth: authReducer,
  paymentStatus: paymentStatusReducer
});

const AppContext = createContext();

export {rootReducer, initialState};
export default AppContext;
