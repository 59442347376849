import './styles.scss';

/**
 * The external dependencies.
 */
import React from 'react';

const LoaderFull = ({ wording }) => (
  <div className="loader-full-wrapper">
    <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>

    {wording && <span>{wording}</span>}
  </div>
);

export default LoaderFull;
