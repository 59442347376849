/**
 * The external dependencies.
 */
import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import Select from 'react-select'

/**
 * The internal dependencies.
 */
import { useGlobalState, useAdAccounts, useAdSet } from 'statecontext/hooks';
import Dropdown from 'components/elements/dropdown';
import AdsList from 'blocks/ads-list';
import Alert from 'components/common/alert';
import Loader from 'components/common/loader';

const AdPreview = () => {
  const [activeAdAccount, setActiveAdAccount] = useState(null);
  const [activeAdSet, setActiveAdSet] = useState(null);
  const [adAccountsList, setAdAccountsList] = useState([])
  const [adSetList, setAdSetList] = useState([])


  /** Auth reducer */
  const {
    getters: {
      getUserFacebookToken: token,
      passwordProviderExists,
      facebookProviderExists
    },
    services
  } = useGlobalState('auth');

  /** AdAccounts service */
  const {
    status: adAccountsStatus,
    data: adAccounts,
    error: adAccountsError
  } = useAdAccounts();

  /** AdAccounts service */
  const {
    status: adSetStatus,
    data: adSet,
    error: adSetError
  } = useAdSet(activeAdAccount && activeAdAccount.value.key);

  useEffect(() => {
    if (adAccounts) {

      if (!activeAdAccount && adAccounts.length > 0) {
        setActiveAdAccount({ value: adAccounts[0], label: adAccounts[0].value })
      }

      const accounts = []
      adAccounts.map((account) => {
        accounts.push({ value: account, label: account.value })
      })
      setAdAccountsList(accounts)
    }
  }, [adAccounts])

  useEffect(() => {
    if (adSet) {
      console.log({ adSet })

      /* if (!activeAdAccount && adAccounts.length > 0) {
         setActiveAdAccount({ value: adAccounts[0], label: adAccounts[0].value })
       }*/

      const adSetVar = []

      adSet.map((adset) => {
        adSetVar.push({ value: adset, label: adset.name })
      })

      //this last variable allows for the ability to not have an adset
      if (adSet.length > 0) {
        adSetVar.push({ value: null, label: "none" })
      }

      setAdSetList(adSetVar)
    }
  }, [adSet])

  /** Facebook unlink account service */
  const [startFacebookUnlinkAccount] = useMutation(services.facebookUnlinkAccount, {
    onSuccess: () => {
      services.getDbUserWithClaims();
    }
  });

  const handleFacebookUnlink = async () => {
    await startFacebookUnlinkAccount();
  }

  const handleOnSelectChange = (activeOption) => {
    setActiveAdAccount(activeOption);
    setActiveAdSet(null)
    setAdSetList([])
  }

  const handleOnSelectAdSet = (activeOption) => {
    setActiveAdSet(activeOption);
  }

  useEffect(() => {
    if (adAccountsError && adAccountsError.details && adAccountsError.details.type === 'OAuthException') {
      if (passwordProviderExists) {
        handleFacebookUnlink();
      } else {
        services.logout();
      }
    }
  }, [adAccountsError]);


  if (adAccountsStatus === 'loading') {
    return <Loader />;
  }

  if (!facebookProviderExists) {
    return (
      <section className="section-ad-preview">
        <div className="section-ad-preview__bar">
          <div className="shell shell--fluid">
            <h1 className="section-ad-preview__title">Facebook Ads Preview</h1>
          </div>
        </div>

        <div className="section-ad-preview__body">
          <Alert variant="danger">You need to connect your facebook account first!</Alert>
        </div>
      </section>
    );
  }

  if (adAccounts && adAccounts.length <= 0) {
    return (
      <section className="section-ad-preview">
        <div className="section-ad-preview__bar">
          <div className="shell shell--fluid">
            <h1 className="section-ad-preview__title">Facebook Ads Preview</h1>
          </div>
        </div>

        <div className="section-ad-preview__body">
          <Alert variant="danger">No facebook ad accounts were found!</Alert>
        </div>
      </section>
    );
  }

  if (adAccountsError) {
    return (
      <section className="section-ad-preview">
        <div className="section-ad-preview__bar">
          <div className="shell shell--fluid">
            <h1 className="section-ad-preview__title">Facebook Ads Preview</h1>
          </div>
        </div>

        <div className="section-ad-preview__body">
          {
            (adAccountsError.details && adAccountsError.details.type === 'OAuthException')
              ? (
                <Alert variant="danger">You need to connect your facebook account first!</Alert>
              ) : (
                <Alert variant="danger">Something went wrong. Try again later.</Alert>
              )}
        </div>
      </section>
    );
  }

  return (
    <section className="section-ad-preview">
      <div className="section-ad-preview__bar">
        <div className="flex justify-between items-center px-16">
          <h1 className="section-ad-preview__title">Facebook Ads Preview</h1>

          {(!adAccountsError && token) && (
            <div className='flex'>
              <Select
                className='mx-2 w-64'
                isDisabled={adSetStatus === 'loading'}
                placeholder={(adSetList && adSetList.length <= 0) ? 'No Ad Set available' : 'Select Ad Set...'}
                noOptionsMessage={({ inputValue }) => `Cannot find Ad Set`}
                options={adSetList}
                onChange={handleOnSelectAdSet}
                value={activeAdSet}
              />

              <Select
                isDisabled={adAccountsStatus === 'loading'}
                className='w-64'
                noOptionsMessage={({ inputValue }) => `Cannot find ads account`}
                value={activeAdAccount}
                onChange={handleOnSelectChange}
                options={adAccountsList} />
            </div>

          )}


        </div>
      </div>

      <div className="section-ad-preview__body">
        {(!adAccountsError && adAccountsStatus !== 'loading') && (
          <AdsList
            adSet={activeAdSet && activeAdSet.value}
            activeAdAccount={activeAdAccount && activeAdAccount.value} />
        )}
      </div>
    </section>
  );
};

export default AdPreview;