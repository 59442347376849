/**
 * The external dependencies.
 */
import React from 'react';

/**
 * The internal dependencies.
 */
import FormSignup from 'components/forms/signup';

const SignUp = () => (
  <section className="section section--auth">
    <FormSignup />
  </section>
);

export default SignUp;