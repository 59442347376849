/**
 * The external dependencies.
 */
import React from 'react';
import NumberFormat from 'react-number-format';

const UsdOutput = ({ value, ...props }) => (
  <NumberFormat
    prefix="$"
    thousandSeparator={true}
    decimalSeparator="."
    displayType="text"
    decimalScale={2}
    value={value}
    {...props}
  />
);

export default UsdOutput;