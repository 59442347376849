import './styles.scss';

/**
 * The external dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {isEmpty, isNil} from 'ramda';

/**
 * The internal dependencies.
 */
import Alert from 'components/common/alert';
import LoaderSimple from 'components/common/loader-simple';

const TableDefault = ({ head, body, isLoading, error }) => {
  /**
   * Checks if there is table body data.
   *
   * @type {Boolean}
   */
  const hasData = !isEmpty(body) && !isNil(body);

  /**
   * Renders the table head.
   */
  const renderTableHead = () => (
    <thead>
      <tr>
        {head.map((headItem) => (
          <th
            className={classNames(headItem.key)}
            key={headItem.key}
          >{headItem.content}</th>
        ))}
      </tr>
    </thead>
  );

  if (isLoading) {
    return (
      <div className="table">
        <table>
          {renderTableHead()}

          <tbody>
            <tr>
              <td colSpan="4" className="td-full">
                <div className="table-loader">
                  <LoaderSimple />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  if (!isEmpty(error) && !isNil(error)) {
    return (
      <div className="table">
        <table>
          {renderTableHead()}

          <tbody>
            <tr>
              <td colSpan="4" className="td-full">
                <Alert variant="danger">{error}</Alert>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div className="table">
      <table>
        {renderTableHead()}

        {
          hasData
          ? (
            <tbody>
              {body.map(({ key, ...row }) => (
                <tr key={key}>
                  {Object.keys(row).map((keyName, i) => (
                    <td
                      className={classNames(keyName)}
                      key={keyName}
                    >{row[keyName]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan="4" className="td-full">
                  <Alert>No available results! Please enter or change the search field value.</Alert>
                </td>
              </tr>
            </tbody>
          )
        }
      </table>
    </div>
  );
};

/**
 * Define the interface of the component.
 *
 * @type {Object}
 */
TableDefault.propTypes = {
  head: PropTypes.array.isRequired,
  body: PropTypes.array.isRequired,
  isLoading: PropTypes.bool
};

/**
 * Define the default props of the component.
 *
 * @type {Object}
 */
TableDefault.defaultProps = {
  isLoading: false,
  error: null
};

export default TableDefault;