/**
 * The external dependencies.
 */
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

/**
 * The internal dependencies.
 */
import config from 'lib/firebase/config'

/**
 * Class for firebase initialize.
 *
 * @class Firebase (name)
 */
class FirebaseApp {
  /**
   * Constructs the object.
   */
  constructor() {
    this.app = !firebase.apps.length ? firebase.initializeApp(config) : firebase.app()

    this.firebase = firebase
    this.auth = firebase.auth()
    this.database = firebase.firestore()
    this.functions = firebase.functions()
  }

  /**
   * Prepare the auth user data.
   *
   * @param  {Object} The user
   * @return {Promise}
   */
  async parseAuthUserData(user, getUser = true) {
    const {claims} = await user.getIdTokenResult(true);
    const userToJSON = await user.toJSON();

    if (getUser) {
      const dbUser = await this.getUserById(userToJSON.uid);

      return {
        ...userToJSON,
        dbUser: dbUser,
        claims: claims || null
      };
    }

    return {
      ...userToJSON,
      claims: claims || null
    };
  }

  /**
   * Gets the user claims.
   *
   * @return {Promise} The user claims.
   */
  getUserClaims() {
    return this.auth.currentUser.getIdTokenResult(true);
  }

  /**
   * Firebase gets the user by identifier.
   *
   * @param  {String}  The uid
   * @return {Promise} The user by identifier.
   */
  getUserById(uid) {
    return new Promise((resolve, reject) => {
      this.database
        .collection('users')
        .doc(uid)
        .get()
        .then(doc => {
          if (doc.exists) {
            resolve(doc.data())
          } else {
            reject(new Error('User not found!'))
          }
        }).catch(reject)
    })
  }

  /**
   * Firebase updates the user data.
   *
   * @param  {String} The uid
   * @param  {Object} The payload
   * @return {Promise}
   */
  updateUserData(uid, payload) {
    return this.database
      .collection('users')
      .doc(uid)
      .set(payload, { merge: true })
  }

  /**
   * Called on database user listener.
   *
   * @param  {String} uid The uid
   * @param  {Function}  callback  The callback
   * @return {Observable}
   */
  onDatabaseUserListener(uid, callback) {
    return this.database
      .collection('users')
      .doc(uid)
      .onSnapshot({includeMetadataChanges: true}, callback)
  }

  /**
   * Firebase updates the current user data.
   *
   * @param  {String} The uid
   * @param  {Object} The payload
   * @return {Promise}
   */
  async updateCurrentUserData(payload) {
    const { uid } = await this.auth.currentUser
    return this.database
      .collection('users')
      .doc(uid)
      .set(payload, { merge: true })
  }

  /**
   * Trigger a firebase network request to create new user.
   *
   * @param  {String} The display name
   * @param  {String} The email
   * @param  {String} The password
   * @return {Promise}
   */
  firebaseSignup(displayName, email, password) {
    return new Promise((resolve, reject) => {
      this.auth.createUserWithEmailAndPassword(email, password)
        .then(async response => {
          const user = response.user

          if (displayName) {
            await user.updateProfile({ displayName })
          }

          const authUser = await this.parseAuthUserData(response.user, false)

          if (displayName) {
            authUser.displayName = displayName
          }

          resolve(authUser)
        }).catch(reject)
    })
  }

  /**
   * Trigger a firebase network request to login the user.
   *
   * @param  {String} The email
   * @param  {String} The password
   * @return {Promise}
   */
  firebaseLogin(email, password) {
    return new Promise((resolve, reject) => {
      this.auth.signInWithEmailAndPassword(email, password)
        .then(async response => {
          const authUser = await this.parseAuthUserData(response.user)

          resolve(authUser)
        }).catch(reject)
    })
  }

  /**
   * Trigger a network request to login user with facebook.
   *
   * @return {Promise}
   */
  firebaseFacebookLogin() {
    const provider = new this.firebase.auth.FacebookAuthProvider()
    provider.addScope('ads_read')
    provider.addScope('ads_management')
    provider.addScope('business_management')

    return new Promise((resolve, reject) => {
      this.auth.signInWithPopup(provider).then(async (result) => {
        const facebookToken = result.credential.accessToken
        const user = result.user
        await this.updateCurrentUserData({fbAccessToken: facebookToken})
        const authUser = await this.parseAuthUserData(user)

        resolve({token: facebookToken, user: authUser})
      }).catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        const email = error.email
        const credential = error.credential

        reject({
          code: errorCode,
          message: errorMessage,
          email: email,
          credential: credential
        })
      })
    })
  }

  /**
   * Trigger a firebase network request
   * to link facebook social account to the extisting user.
   *
   * @return {Promise}
   */
  facebookLinkWithPopup() {
    const provider = new this.firebase.auth.FacebookAuthProvider()
    provider.addScope('ads_read')
    provider.addScope('ads_management')

    return new Promise((resolve, reject) => {
      this.auth.currentUser
        .linkWithPopup(provider)
        .then((result) => {
          const credential = result.credential;
          const user = result.user;

          resolve({credential, user})
        }).catch(reject)
    })
  }

  /**
   * Trigger a firebase network request
   * to unlink facebook account from current user.
   *
   * @return {Promise}
   */
  facebookUnlinkAccount() {
    return this.auth.currentUser.unlink('facebook.com')
  }

  /**
   * Trigger a network request
   * to link account wtih credentials.
   *
   * @return {Promise}
   */
  linkWithCredentials(credentials) {
    return this.auth.currentUser.linkWithCredential(credentials)
  }

  /**
   * Listen on user auth change.
   *
   * @param  {Function} The callback
   * @return {Promise}
   */
  onAuthUserListener(callback) {
    return this.auth.onAuthStateChanged(async user => {
      if (user) {
        const authUser = await this.parseAuthUserData(user)

        callback(authUser)
      } else {
        callback(user)
      }
    })
  }

  /**
   * Start a server side network request to graph api.
   *
   * @param  {Object} The data
   * @return {Promise}
   */
  requestGraphApi(data) {
    const callable = this.functions.httpsCallable('requestGraphApi')

    return callable(data)
  }

  /**
   * Start a server side network request to create stripe session.
   *
   * @param  {Object} The data
   * @return {Promise}
   */
  createStripeSession(data) {
    const callable = this.functions.httpsCallable('createStripeSession')

    return callable(data)
  }

  /**
   * Start a server side network request to create stripe session.
   *
   * @param  {Object} The data
   * @return {Promise}
   */
  createStripeSessionSetup(data) {
    const callable = this.functions.httpsCallable('createStripeSessionSetup')

    return callable(data)
  }

  /**
   * Start a server side network request to retrieve stripe customer.
   *
   * @param  {String} The customer id
   * @return {Promise}
   */
  getStripeCustomer(customer_id) {
    const callable = this.functions.httpsCallable('getStripeCustomer')

    return new Promise((resolve, reject) => {
      callable(customer_id)
        .then(res => resolve(res.data))
        .catch(reject)
    })
  }

  /**
   * Start a server side network request to retrieve stripe payment method.
   *
   * @param  {String} The customer id
   * @return {Promise}
   */
  getStripePaymentMethod(key, payment_id) {
    const callable = this.functions.httpsCallable('getStripePaymentMethod')

    return new Promise((resolve, reject) => {
      callable(payment_id)
        .then(res => resolve(res.data))
        .catch(reject)
    })
  }

  /**
   * Start a server side network request to update stripe payment method.
   *
   * @param  {String} The customer id
   * @return {Promise}
   */
  updateStripePaymentMethod(payment_id, metadata = {}) {
    const callable = this.functions.httpsCallable('updateStripePaymentMethod')

    return callable({ payment_id, metadata })
  }

  /**
   * Start a server side network request to update stripe subscription.
   *
   * @return {Promise}
   */
  updateStripeSubscription(options) {
    const callable = this.functions.httpsCallable('updateStripeSubscription')

    return callable(options)
  }

  /**
   * Start a server side network request to cancel stripe subscription.
   *
   * @param  {Object} The options id
   * @return {Promise}
   */
  cancelStripeSubscription(options) {
    const callable = this.functions.httpsCallable('cancelStripeSubscription')

    return callable(options)
  }

  /**
   * Start a server side network request to create stripe user.
   *
   * @return {Promise}
   */
  createStripeCustomer(user) {
    const callable = this.functions.httpsCallable('createStripeCustomer')

    return callable(user)
  }

  /**
   * Start a server side network request to create stripe invoice payment.
   *
   * @return {Promise}
   */
  stripePayInvoice(payload) {
    const callable = this.functions.httpsCallable('stripePayInvoice')

    return callable(payload)
  }
}

export default FirebaseApp
