import './styles.scss';

/**
 * The external dependencies.
 */
import React from 'react';
import {useMutation} from 'react-query';

/**
 * The internal dependencies.
 */
import {useGlobalState} from 'statecontext/hooks';
import Button from 'components/common/button';
import Alert from 'components/common/alert';

const LinkSocialAccount = () => {
  /** Auth reducer */
  const {
    getters: {
      facebookUserInfo,
      passwordProviderExists
    },
    services
  } = useGlobalState('auth');

  /** Facebook link account service */
  const [startFacebookLinkAccount, facebookLinkAccountStatus] = useMutation(services.facebookLinkWithPopup, {
    onSuccess: () => {
      services.getDbUserWithClaims();
    }
  });

  /** Facebook unlink account service */
  const [startFacebookUnlinkAccount] = useMutation(services.facebookUnlinkAccount, {
    onSuccess: () => {
      services.getDbUserWithClaims();
    }
  });

  const handleFacebookConnect = async event => {
    event.preventDefault();

    await startFacebookLinkAccount();
  }

  const handleFacebookUnlink = async event => {
    event.preventDefault();

    await startFacebookUnlinkAccount();
  }

  return (
    <React.Fragment>
      {facebookLinkAccountStatus.error?.data?.code === 'auth/credential-already-in-use' && (
        <Alert className="alert--small" variant="danger">This credential is already associated with a different user account.</Alert>
      )}

      {
        facebookUserInfo()
        ? (
          <ul className="sso-list">
            <li>
              <div className="sso-account">
                <figure className="sso-account__image">
                  <img src={facebookUserInfo().photoURL} alt="" />
                </figure>

                <div className="sso-account__content">
                  <h4 className="sso-account__title">Name: <strong>{facebookUserInfo().displayName}</strong></h4>
                  <h4 className="sso-account__title">Email: <strong>{facebookUserInfo().email}</strong></h4>
                </div>
              </div>

              {passwordProviderExists && (
                <Button
                  type="button"
                  variant="facebook"
                  onClick={handleFacebookUnlink}
                >
                  <svg width="25" height="25" viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><path d="M0 0h120v120H0V0z"/><path d="M113.377 0H6.623A6.622 6.622 0 000 6.623v106.754A6.623 6.623 0 006.623 120h57.473V73.53H48.457V55.42h15.639V42.062c0-15.5 9.466-23.939 23.293-23.939 6.623 0 12.315.493 13.974.714v16.197l-9.59.005c-7.52 0-8.975 3.573-8.975 8.817v11.562h17.934l-2.336 18.11H82.798V120h30.58a6.623 6.623 0 006.622-6.623V6.623A6.622 6.622 0 00113.377 0" fill="#FFF"/></g></svg>

                  <span>Unlink Facebook Account</span>
                </Button>
              )}
            </li>
          </ul>
        ) : (
          <ul className="sso-list">
            <li>
              <Button
                type="button"
                variant="facebook"
                onClick={handleFacebookConnect}
              >
                <svg width="30" height="30" viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><path d="M0 0h120v120H0V0z"/><path d="M113.377 0H6.623A6.622 6.622 0 000 6.623v106.754A6.623 6.623 0 006.623 120h57.473V73.53H48.457V55.42h15.639V42.062c0-15.5 9.466-23.939 23.293-23.939 6.623 0 12.315.493 13.974.714v16.197l-9.59.005c-7.52 0-8.975 3.573-8.975 8.817v11.562h17.934l-2.336 18.11H82.798V120h30.58a6.623 6.623 0 006.622-6.623V6.623A6.622 6.622 0 00113.377 0" fill="#FFF"/></g></svg>

                <span>Connect with Facebook</span>
              </Button>
            </li>
          </ul>
        )
      }
    </React.Fragment>
  );
};

export default LinkSocialAccount;
