import './styles.scss';

/**
 * The external dependencies.
 */
import React, {useReducer} from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import {BrowserRouter as Router} from 'react-router-dom';
import {CSSTransition} from 'react-transition-group';

/**
 * The internal dependencies.
 */
import AppContext, {rootReducer, initialState} from 'statecontext/store';
import stripeConfigs from 'lib/stripe-configs';
import withFirebase from 'lib/hocs/with-firebase';
import useWithAuthSubscription from 'lib/hooks/use-with-auth-subscription';
import Routes from 'router/index';
import Loader from 'components/common/loader';

/**
 * Setup Stripe.js and the Elements provider
 */
const stripePromise = loadStripe(stripeConfigs.public_key);

const App = ({ firebase }) => {
  const [state, dispatch] = useReducer(rootReducer, initialState);
  const authIsLoading = useWithAuthSubscription(firebase, dispatch);
 
  return (
    <AppContext.Provider value={[state, dispatch]}>
      <div className="wrapper">
        <Elements stripe={stripePromise}>
          <Router>
            <CSSTransition
              in={authIsLoading}
              timeout={400}
              classNames="fade-transition"
              unmountOnExit
              appear
            >
              <Loader />
            </CSSTransition>

            {!authIsLoading && <Routes />}
          </Router>
        </Elements>
      </div>
    </AppContext.Provider>
  );
};

export default withFirebase(App);
