import './styles.scss';

/**
 * The external dependencies.
 */
import React from 'react';
import {Formik, Form, Field} from 'formik';

/**
 * The internal dependencies.
 */
import validationSchema from 'components/forms/search/schema';
import FormikField from 'components/common/formik-field';
import Button from 'components/common/button';

const FormSearch = ({ loading, setSearchQuery }) => {
  const handleFormSubmit = ({ search }) => {
    if (!loading) {
      setSearchQuery(search);
    }
  }

  return (
    <div className="form-search">
      <Formik
        validationSchema={validationSchema}
        initialValues={{ search: '' }}
        onSubmit={handleFormSubmit}
      >
        {props => (
          <Form>
            <div className="form__inner">
              <Field
                component={FormikField}
                name="search"
                placeholder="Search For Interest"
              />

              <Button
                disabled={loading}
                type="submit"
              >Search</Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormSearch;
