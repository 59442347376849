/**
 * The external dependencies.
 */
import React from 'react'

/**
 * The internal dependencies.
 */
import {usePublicPlans} from 'statecontext/hooks';
import Plan from 'components/plan';
import LoaderSimple from 'components/common/loader-simple';

const Subscriptions = ({ isSubscribed, subscription }) => {
  const { data, status } = usePublicPlans();

  return (
    <section className="section-plans">
      <header className="section__head">
        <div className="container">
          <div className="section__head-inner">
            <h1 className="section__title">Plans &amp; Pricing</h1>

            <div className="section__entry">
              <p>Here you have the option to pick which of our subscription plans will work in your favor the most.</p>
            </div>
          </div>
        </div>
      </header>

      <div className="section__body">
        <div className="container">
          <div className="cols cols--justify-center">
            {
              (status !== 'loading' && data)
              ? data.map(plan => (
                <div
                  key={plan.id}
                  className="col col--size-plan"
                >
                  <Plan
                    title={plan.title}
                    icon={plan.icon}
                    price={plan.price}
                    list={plan.list}
                    planId={plan.plan_id}
                    subscription={plan.subscription}
                    active={isSubscribed && subscription.plan_id === plan.plan_id}
                    isSubscribed={isSubscribed}
                    subData={subscription}
                  />
                </div>
              ))
              : (
                <div className="col col--size-plan">
                  <div className="plan plan--placeholder">
                    <LoaderSimple />
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </section>
  );
};

export default Subscriptions;