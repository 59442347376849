/**
 * The external dependencies.
 */
import React from 'react';
import {Formik, Form, Field} from 'formik';
import {useMutation} from 'react-query';

/**
 * The internal dependencies.
 */
import {useGlobalState} from 'statecontext/hooks';
import validationSchema from 'components/forms/login/schema';
import FormikField from 'components/common/formik-field';
import Button from 'components/common/button';
import LoaderSuspense from 'components/common/loader-suspense';

const FormLinkAccount = ({ code, message, email, credential }) => {
  /** Auth reducer */
  const {services} = useGlobalState('auth');

  /** Facebook link with existing account service */
  const [startFacebookLinkAccount, facebookLinkAccountStatus] = useMutation(services.facebookLinkWithExistingAccount, {
    onSuccess: () => {
      services.getDbUserWithClaims();
    }
  });

  const handleFormSubmit = async values => {
    await startFacebookLinkAccount({ values, credential });
  }

  return (
    <div className="form-auth form-auth--alt">
      <Formik
        validationSchema={validationSchema}
        initialValues={{ email: email, password: '' }}
        onSubmit={handleFormSubmit}
      >
        {props => (
          <Form>
            <LoaderSuspense
              maintainContent
              isLoading={facebookLinkAccountStatus.status === 'loading'}
              hasError={!!facebookLinkAccountStatus.error?.message}
              errorMessage={facebookLinkAccountStatus.error?.message}
            >
              <header className="form__head">
                <h1 className="form__title">An account already exists with the same email address. Enter your password to link the facebook account.</h1>
              </header>

              <div className="form__body">
                <div className="form__inner">
                  <div className="form__row">
                    <Field
                      component={FormikField}
                      name="email"
                      placeholder="Email"
                      readOnly
                      disabled
                    />
                  </div>

                  <div className="form__row">
                    <Field
                      component={FormikField}
                      type="password"
                      name="password"
                      placeholder="Password"
                    />
                  </div>

                  <div className="form__actions">
                    <Button type="submit">Link Facebook Account</Button>
                  </div>
                </div>
              </div>
            </LoaderSuspense>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormLinkAccount;
