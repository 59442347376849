/**
 * The external dependencies.
 */
import { useContext } from 'react';
import { useQuery } from 'react-query';

/**
 * The internal dependencies.
 */
import Api from 'lib/api';
import AppContext from 'statecontext/store';

import authServices from 'statecontext/auth/services';
import authGetters from 'statecontext/auth/getters';

import paymentStatusServices from 'statecontext/payment-status/services';
import paymentStatusGetters from 'statecontext/payment-status/getters';

export const useGlobalState = (namespace = null) => {
  const [state, dispatch] = useContext(AppContext);
  const store = {
    state: { ...state },
    getters: {
      auth: { ...authGetters(state) },
      paymentStatus: { ...paymentStatusGetters(state) }
    },
    services: {
      auth: { ...authServices(dispatch) },
      paymentStatus: { ...paymentStatusServices(dispatch) }
    }
  };

  if (namespace && typeof store.state[namespace] !== 'undefined') {
    return {
      state: { ...store.state[namespace] },
      services: { ...store.services[namespace] },
      getters: { ...store.getters[namespace] }
    };
  }

  return store;
};

export const useAdAccounts = () => {
  const { getters: { getUserFacebookToken } } = useGlobalState('auth');
  const queryStatus = useQuery(
    getUserFacebookToken && ['adAccounts', getUserFacebookToken],
    (...props) => Api.requestFbUserAdAccounts(...props)
  );

  return queryStatus;
};

export const useAdSet = (adAccountId) => {
  const { getters: { getUserFacebookToken } } = useGlobalState('auth');
  const queryStatus = useQuery(
    getUserFacebookToken && ['adset', getUserFacebookToken, adAccountId],
    (...props) => Api.requestAdSet(...props)
  );

  return queryStatus;
};

export const useUserActiveAds = (adAccountId, adSet) => {
  const { getters: { getUserFacebookToken } } = useGlobalState('auth');
  const queryStatus = useQuery(
    adAccountId && [`userActiveAds-${adAccountId}`, getUserFacebookToken, adAccountId, adSet],
    (...props) => Api.requestFbUserActiveAds(...props),
    { refetchOnWindowFocus: false }
  );

  return queryStatus;
};

export const useStripeCustomer = (stripeCustomerId) => {
  const queryStatus = useQuery(
    stripeCustomerId && ['stripeCustomer', stripeCustomerId],
    (...props) => Api.retrievetStripeCustomer(...props)
  );

  return queryStatus;
};

export const usePublicPlans = () => {
  const queryStatus = useQuery(
    ['publicPlans'],
    (...props) => Api.getPublicPlans(...props)
  );

  return queryStatus;
};
