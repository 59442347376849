import 'assets/styles/modules/form-auth.scss';

/**
 * The external dependencies.
 */
import React from 'react';
import {Link} from 'react-router-dom';
import {Formik, Form, Field} from 'formik';
import {useMutation} from 'react-query';

/**
 * The internal dependencies.
 */
import {useGlobalState} from 'statecontext/hooks';
import validationSchema from 'components/forms/signup/schema';
import FormikField from 'components/common/formik-field';
import Button from 'components/common/button';
import LoaderSuspense from 'components/common/loader-suspense';

const FromSignup = () => {
  /** Auth reducer */
  const {services} = useGlobalState('auth');

  /** Register service */
  const [startRegister, registerStatus] = useMutation(services.register);

  const handleFormSubmit = async values => {
    await startRegister(values);
  }

  return (
    <div className="form-auth">
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          displayName: '',
          email: '',
          password: ''
        }}
        onSubmit={handleFormSubmit}
      >
        {props => (
          <Form id="signup">
            <LoaderSuspense
              maintainContent
              isLoading={registerStatus.status === 'loading'}
              hasError={!!registerStatus.error}
              errorMessage={registerStatus.error && registerStatus.error.message}
            >
              <header className="form__head">
                <h1 className="form__title">Create Account</h1>
              </header>

              <div className="form__body">
                <div className="form__inner">
                  <div className="form__row">
                    <Field
                      component={FormikField}
                      name="displayName"
                      placeholder="Name"
                    />
                  </div>

                  <div className="form__row">
                    <Field
                      component={FormikField}
                      name="email"
                      placeholder="Email"
                    />
                  </div>

                  <div className="form__row">
                    <Field
                      component={FormikField}
                      type="password"
                      name="password"
                      placeholder="Password"
                    />
                  </div>

                  <div className="form__actions">
                    <Button type="submit">Sign Up</Button>
                  </div>

                  <div className="form__outro">
                    <p>Already a memeber? <Link className="link" to="/login">Sign in</Link></p>
                  </div>
                </div>
              </div>
            </LoaderSuspense>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FromSignup;
