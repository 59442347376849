/**
 * The internal dependencies.
 */
import Api from 'lib/api';
import {
  authSuccess,
  authSetUserClaims
} from 'statecontext/auth/actions';

function FacebookLoginException(message, details) {
  const error = new Error(message);
  error.data = details;
  return error;
}

const services = dispatch => ({
  /**
   * Save the user data.
   *
   * @param {Object} user The user
   */
  receiveUser: user => {
    dispatch(authSuccess(user));
  },

  /**
   * Gets the user claims.
   */
  getUserClaims: async () => {
    const {claims} = await Api.getUserClaims();

    dispatch(authSetUserClaims(claims));
  },

  /**
   * Gets the database user with claims.
   */
  getDbUserWithClaims: async () => {
    const authUser = await Api.parseAuthUserData(Api.auth.currentUser);

    dispatch(authSuccess(authUser));
  },

  /**
   * Start a network request to login user with email and password pair.
   *
   * @param  {Object} values The email and password data
   * @return {Promise}
   */
  login: async values => {
    try {
      const user = await Api.login(values.email, values.password);

      dispatch(authSuccess(user));

      return user;
    } catch (error) {
      const errorMessage = error.message || 'Authentication failed';
      throw new Error(errorMessage);
    }
  },

  /**
   * Start a netowrk request to login user with facebook account.
   *
   * @return {Promise}
   */
  facebookLogin: async () => {
    try {
      const response = await Api.firebaseFacebookLogin();
      await Api.updateCurrentUserData({fbAccessToken: response.token});

      dispatch(authSuccess(response.user));

      return response;
    } catch (error) {
      throw new FacebookLoginException(error.message, error);
    }
  },

  /**
   * Start a network request to link facebook account.
   *
   * @return {Promise}
   */
  facebookLinkWithPopup: async () => {
    try {
      const response = await Api.facebookLinkWithPopup();
      await Api.updateCurrentUserData({fbAccessToken: response.credential.accessToken});

      return response;
    } catch (error) {
      const errorMessage = error.message || 'Failed to link account';
      throw new FacebookLoginException(errorMessage, error);
    }
  },

  /**
   * Start a network request to unlink facebook account.
   *
   * @return {Promise}
   */
  facebookUnlinkAccount: async () => {
    try {
      await Api.facebookUnlinkAccount();
      await Api.updateCurrentUserData({fbAccessToken: null});

      return {status: 'success'};
    } catch (error) {
      const errorMessage = error.message || 'Failed to unlink account';
      throw new Error(errorMessage);
    }
  },

  /**
   * Start a network request to link facebook account
   * to an already existing one with the same email address.
   *
   * @param  {Object}  payload     The payload
   * @param  {Object}  values      The form data
   * @param  {Object}  credential  The facebook credential
   * @return {Promise}
   */
  facebookLinkWithExistingAccount: async ({ values, credential }) => {
    try {
      await Api.auth.signInWithEmailAndPassword(values.email, values.password);
      const linkResponse = await Api.linkWithCredentials(credential);
      await Api.updateCurrentUserData({fbAccessToken: linkResponse.credential.accessToken});
      const authUser = await Api.parseAuthUserData(linkResponse.user);

      dispatch(authSuccess(authUser));
    } catch (error) {
      const errorMessage = error.message || 'Something went wrong. Try again later';
      throw new Error(errorMessage);
    }
  },

  /**
   * Start a network request to register user.
   *
   * @param  {Object} values The form data
   * @return {Promise}
   */
  register: async values => {
    try {
      const user = await Api.signup(values);

      dispatch(authSuccess(user));

      return user;
    } catch (error) {
      const errorMessage = error.message || 'Failed creating your account';
      throw new Error(errorMessage);
    }
  },

  /**
   * Start a network request to logout the user.
   *
   * @return {Void}
   */
  logout: async () => {
    try {
      await Api.logout();

      dispatch(authSuccess(null));
    } catch (err) {}
  },
});

export default services;
