/**
 * The external dependencies.
 */
import {pathOr} from 'ramda';

const getters = state => ({
  /**
   * Checks if the user is logged in.
   */
  isUserLoggedIn: !!state.auth.user,

  /**
   * Gets the user subscription.
   */
  subscription: pathOr(false, ['user', 'claims', 'subscription'], state.auth),

  /**
   * Gets the stripe customer id.
   */
  stripeCustomerId: pathOr(null, ['user', 'claims', 'stripe_customer_id'], state.auth),

  /**
   * Gets the user facebook token.
   */
  getUserFacebookToken: pathOr(null, ['user', 'dbUser', 'fbAccessToken'], state.auth),

  /**
   * Gets the facebook user info.
   *
   * @return {Object/Boolean} The facebook user information.
   */
  facebookUserInfo: () => {
    const user = state.auth.user;
    const facebookLink = user?.providerData.find(provider => provider.providerId === 'facebook.com');

    if (!!facebookLink) return facebookLink;

    return !!facebookLink;
  },

  /**
   * Checks if password provider exists.
   */
  passwordProviderExists: !!state.auth.user?.providerData.find(provider => provider.providerId === 'password'),

  /**
   * Checks if facebook provider exists.
   */
  facebookProviderExists: !!state.auth.user?.providerData.find(provider => provider.providerId === 'facebook.com')
});

export default getters;