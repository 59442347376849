/**
 * The external dependencies.
 */
import React from 'react';
import {Route, Redirect} from 'react-router-dom';

/**
 * The internal dependencies.
 */
import AuthLayout from 'layouts/auth';
import {useGlobalState} from 'statecontext/hooks';

const AuthRoute = ({ component: Component, ...restProps }) => {
  const {getters: {isUserLoggedIn}} = useGlobalState('auth');

  return (
    <Route
      {...restProps}
      render={(routeProps) => {
        if (isUserLoggedIn) return <Redirect to="/" />;

        return (
          <AuthLayout>
            <Component {...routeProps} />
          </AuthLayout>
        );
      }}
    />
  );
};

export default AuthRoute;