import './styles.scss';

/**
 * The external dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {UID} from 'react-uid';

/**
 * The internal dependencies.
 */
import withFirebase from 'lib/hocs/with-firebase'
import {useGlobalState} from 'statecontext/hooks';
import useStripeSession from 'lib/hooks/use-stripe-session';
import useUpdateStripeSubscription from 'lib/hooks/use-update-stripe-subscription';
import Button from 'components/common/button';
import UsdOutput from 'components/common/usd-output';
import LoaderFull from 'components/common/loader-full';

const Plan = ({
  firebase,
  title,
  icon,
  price,
  subscription,
  list,
  planId,
  active,
  isSubscribed,
  subData
}) => {
  const {
    state: {user},
    getters: {stripeCustomerId}
  } = useGlobalState('auth');
  const {state: {data: paymentStatus}} = useGlobalState('paymentStatus');

  const {
    startStripeCheckout,
    sessionLoading,
    sessionError
  } = useStripeSession(firebase, {
    customer_id: stripeCustomerId,
    subscription_data: {
      items: [{plan: planId}]
    }
  })

  const retry = event => {
    event.preventDefault()

    startStripeCheckout()
  }

  const {
    updateSubscription,
    subIsLoading
  } = useUpdateStripeSubscription(firebase, {
    cancel_at_period_end: false,
    user_id: user.uid,
    sub_id: subData.id,
    new_plan_id: planId
  })

  return (
    <div className="plan">
      {(sessionLoading || subIsLoading || paymentStatus.loading || sessionError) && (
        <div className="plan__overlay">
          {
            sessionError
            ? <h5 className="plan__alert"><span>Failed to start payment session.</span><a onClick={retry} href="#">Try again</a></h5>
            : <LoaderFull />
          }
        </div>
      )}

      <header className="plan__head">
        <h4 className="plan__title">{title}</h4>

        <div className="plan__icon">
          <i className="material-icons">{icon}</i>
        </div>

        <div className="plan__entry">
          <p><UsdOutput value={price} /> / {subscription}</p>
        </div>
      </header>

      <div className="plan__body">
        <ul className="plan__list">
          <UID>
            {(_, uid) => list.map(item => (
              <li key={uid(item)}>
                <i className="material-icons">check_circle</i>

                <span>{item}</span>
              </li>
            ))}
          </UID>
        </ul>
      </div>

      <footer className="plan__foot">
        <div className="plan__actions">
          {
            active
            ? (
              <Button
                disabled
                rounded
              >Current Plan</Button>
            ):(
              <React.Fragment>
                {
                  !isSubscribed
                  ? (
                    <Button
                      disabled={sessionLoading}
                      onClick={startStripeCheckout}
                      rounded
                      variant="outline-primary"
                    >Subscribe</Button>
                  ):(
                    <Button
                      disabled={subIsLoading}
                      onClick={updateSubscription}
                      rounded
                      variant="outline-primary"
                    >Upgrade Plan</Button>
                  )
                }
              </React.Fragment>
            )
          }
        </div>
      </footer>
    </div>
  )
}

/**
 * Define the interface of the component.
 *
 * @type {Object}
 */
Plan.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  subscription: PropTypes.oneOf(['monthly', 'yearly']),
  list: PropTypes.array,
  planId: PropTypes.string,
  active: PropTypes.bool,
  isSubscribed: PropTypes.bool
}

/**
 * Define the default props of the component.
 *
 * @type {Object}
 */
Plan.defaultProps = {
  subscription: 'monthly'
}

export default withFirebase(Plan)