/**
 * The external dependencies.
 */
import * as yup from 'yup'

/**
 * Creates a schema for login form validation.
 *
 * @return {Object}
 */
export default () => {
  const email = yup
    .string()
    .email('E-mail is not valid!')
    .required('E-mail is required!')

  const password = yup
    .string()
    .min(6, 'Password must be at least 6 characters!')
    .required('Password is required!')

  return yup
    .object()
    .shape({
      email,
      password
    })
}