/**
 * The internal dependencies.
 */
import {paymentStatusSet} from 'statecontext/payment-status/actions';

const services = dispatch => ({
  /**
   * Sets the payment status.
   *
   * @param {Object} data The data
   */
  setPaymentStatus: (data) => {
    dispatch(paymentStatusSet(data));
  }
});

export default services;
