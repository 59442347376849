/**
 * The external dependencies.
 */
import { useState } from 'react'

/**
 * Hook to handle stripe subscription update.
 *
 * @param  {Object} The options
 * @return {Object}
 */
export default function useUpdateStripeSubscription(
  firebase,
  {
    user_id,
    sub_id,
    new_plan_id,
    ...options
  }
) {
  const [newSubscription, setNewSubscription] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const updateSubscription = async () => {
    setNewSubscription(null)
    setError(null)
    setLoading(true)

    try {
      const sub = await firebase.updateStripeSubscription({ user_id, sub_id, new_plan_id, ...options })

      setNewSubscription(sub)
      setLoading(false)
    } catch(error) {
      console.log(error)
      setError('Failed to upgrade subscription')
      setLoading(false)
    }
  }

  return {
    updateSubscription,
    newSubscription,
    subIsLoading: loading,
    subError: error
  }
}
