/**
 * The external dependencies.
 */
import React from 'react'
import { Switch } from 'react-router-dom'

/**
 * The internal dependencies.
 */
import useUserWebhooksListener from 'lib/hooks/use-user-webhooks-listener'
import routes from 'router/routes'

/**
 * The high order components.
 */
import withFirebase from 'lib/hocs/with-firebase'

/**
 * Renders the router routes component.
 *
 * @return {React.FC}
 */
const Routes = ({ firebase }) => {
  useUserWebhooksListener(firebase)

  return (
    <Switch>
      {routes.map(({ routeComponent: RouteComponent, ...route }) => <RouteComponent {...route} />)}
    </Switch>
  )
}

export default withFirebase(React.memo(Routes))