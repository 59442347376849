/**
 * The external dependencies.
 */
import {useState, useEffect} from 'react';

/**
 * The internal dependencies.
 */
import {authSuccess} from 'statecontext/auth/actions';

/**
 * Hook to handle firebase auth state change.
 *
 * @return {Boolean}
 */
export default function useWithAuthSubscription(firebase, dispatch) {
  /**
   * The default state of the firebase auth.
   *
   * @type {Boolean}
   */
  const [authIsLoading, setAuthIsLoading] = useState(true);

  /**
   * Memoized subscription to the firebase auth.
   *
   * @return {Function}
   */
  const subscribe = () => {
    const unsubscribeAuthListener = firebase.onAuthUserListener((user) => {
      dispatch(authSuccess(user));
      setAuthIsLoading(false);
      unsubscribeAuthListener();
    });
  }

  /**
   * Subscribe to the firebase auth
   * and unsubscribe on component unmount.
   *
   * @return {Function}
   */
  useEffect(() => {
    subscribe()
  }, []) // eslint-disable-line

  return authIsLoading;
};