import 'assets/styles/modules/form-auth.scss';

/**
 * The external dependencies.
 */
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Formik, Form, Field} from 'formik';
import {useMutation} from 'react-query';

/**
 * The internal dependencies.
 */
import {useGlobalState} from 'statecontext/hooks';
import validationSchema from 'components/forms/login/schema';
import FormikField from 'components/common/formik-field';
import Button from 'components/common/button';
import LoaderSuspense from 'components/common/loader-suspense';
import Modal from 'blocks/modal';
import FormLinkAccount from 'blocks/form-link-account';

const FromLogin = (props) => {
  /** Auth reducer */
  const {services} = useGlobalState('auth');

  /** @type {Object} Facebook link account modal state */
  const [linkModal , setLinkModal] = useState({ visible: false, props: {} });

  /** Login service */
  const [startLogin, loginStatus] = useMutation(services.login);

  /** Facebook login service */
  const [startFacebookLogin, facebookLoginStatus] = useMutation(services.facebookLogin, {
    onError: error => {
      if (error?.data?.code === 'auth/account-exists-with-different-credential') {
        setLinkModal((state) => ({ visible: true, props: error.data }));
      }
    }
  });

  /** @type {Boolean} Checks if the component is running the request */
  const isBusy = loginStatus.status === 'loading' || facebookLoginStatus.status === 'loading';

  const handleFormSubmit = async values => {
    await startLogin(values);
  }

  const handleFacebookLogin = async event => {
    event.preventDefault();

    await startFacebookLogin();
  }

  const handleLinkModalToggle = event => {
    event.preventDefault();
    setLinkModal((state) => ({ ...state, visible: false }));
  }

  return (
    <div className="form-auth">
      <Formik
        validationSchema={validationSchema}
        initialValues={{ email: '', password: '' }}
        onSubmit={handleFormSubmit}
      >
        {props => (
          <Form>
            <LoaderSuspense
              maintainContent
              isLoading={isBusy}
              hasError={!!loginStatus.error}
              errorMessage={loginStatus.error && loginStatus.error.message}
            >
              <header className="form__head">
                <h1 className="form__title">Sign in</h1>
              </header>

              <div className="form__body">
                <div className="form__inner">
                  <div className="form__row">
                    <Field
                      component={FormikField}
                      name="email"
                      placeholder="Email"
                    />
                  </div>

                  <div className="form__row">
                    <Field
                      component={FormikField}
                      type="password"
                      name="password"
                      placeholder="Password"
                    />
                  </div>

                  <div className="form__actions">
                    <Button type="submit">Sign in</Button>
                    <Button
                      type="button"
                      onClick={handleFacebookLogin}
                      variant="facebook"
                    >
                      <svg width="30" height="30" viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><path d="M0 0h120v120H0V0z"/><path d="M113.377 0H6.623A6.622 6.622 0 000 6.623v106.754A6.623 6.623 0 006.623 120h57.473V73.53H48.457V55.42h15.639V42.062c0-15.5 9.466-23.939 23.293-23.939 6.623 0 12.315.493 13.974.714v16.197l-9.59.005c-7.52 0-8.975 3.573-8.975 8.817v11.562h17.934l-2.336 18.11H82.798V120h30.58a6.623 6.623 0 006.622-6.623V6.623A6.622 6.622 0 00113.377 0" fill="#FFF"/></g></svg>

                      <span>Sign in with Facebook</span>
                    </Button>
                  </div>

                  <div className="form__outro">
                    <p>Don't have an account? <Link className="link" to="/signup">Sign up</Link></p>
                  </div>
                </div>
              </div>
            </LoaderSuspense>
          </Form>
        )}
      </Formik>

      <Modal
        toggle={handleLinkModalToggle}
        open={linkModal.visible}
      >
        <FormLinkAccount {...linkModal.props} />
      </Modal>
    </div>
  );
};

export default FromLogin;
