import './styles.scss';

/**
 * The external dependencies.
 */
import React from 'react';

/**
 * The internal dependencies.
 */
import { useUserActiveAds } from 'statecontext/hooks';
import Alert from 'components/common/alert';
import LoaderSingle from 'components/common/loader-single';

const AdsList = ({ activeAdAccount, adSet }) => {
  /** UserActiveAds service */
  const {
    status: userActiveAdsStatus,
    data: userActiveAds,
    error: userActiveAdsError
  } = useUserActiveAds(activeAdAccount?.key, adSet && adSet.id);
  const isEmpty = userActiveAds && userActiveAds.length;

  console.log({ userActiveAds })

  if (userActiveAdsStatus === 'loading') {
    return (
      <LoaderSingle />
    );
  }

  if (userActiveAdsError) {
    return (
      <Alert variant="danger">Something went wrong. Try again later.</Alert>
    );
  }

  return isEmpty ? (
    <div className="w-full px-4">
      <div className="grid grid-cols-3">
        {userActiveAds.map(item => (
          <div className='w-full flex items-center justify-center py-4'>
            <div
              className="border-gray shadow-lg rounded-md overscroll-none "
              key={item.id}
              dangerouslySetInnerHTML={{ __html: item.previews.data[0].body }}
            />
          </div>

        ))}
      </div>
    </div>
  ) : (
      <Alert>No ads associated with <strong>{activeAdAccount?.value}</strong></Alert>
    );
};

export default React.memo(AdsList);