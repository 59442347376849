import './styles.scss';

/**
 * The external dependencies.
 */
import React from 'react';

/**
 * The internal dependencies.
 */
import Logo from 'components/logo';

const HeaderAuth = () => (
  <header className="header-auth">
    <Logo />
  </header>
);

export default HeaderAuth;
