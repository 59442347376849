import 'components/plan/styles.scss';

/**
 * The external dependencies.
 */
import React, { useState } from 'react';
import moment from 'moment';
import {useQuery} from 'react-query';

/**
 * The internal dependencies.
 */
import Api from 'lib/api';
import withFirebase from 'lib/hocs/with-firebase'
import UsdOutput from 'components/common/usd-output'
import LoaderSuspense from 'components/common/loader-suspense'
import LoaderSingle from 'components/common/loader-single'
import Button from 'components/common/button'

const ProfileCurrentPlan = ({ firebase, subId, planId, nextInvoiceDate, isCancelAtPeriodEnd }) => {
  const { data, status, error } = useQuery(
    planId && ['profileCurrentPlan', planId],
    (...props) => Api.getSinglePublicPlan(...props)
  );
  const [localIsCancelAtPeriodEnd, setLocalIsCancelAtPeriodEnd] = useState(isCancelAtPeriodEnd);
  const [cancelIsLoading, setCancelIsLoading] = useState(false);

  const cancelSubscription = () => {
    setCancelIsLoading(true);
    firebase.cancelStripeSubscription({
      sub_id: subId,
      cancel_at_period_end: true
    }).then(() => {
      setLocalIsCancelAtPeriodEnd(true);
      setCancelIsLoading(false);
    });
  }

  return (
    <LoaderSuspense
      loaderComponent={LoaderSingle}
      isLoading={status === 'loading' || cancelIsLoading}
      hasError={!!error}
    >
      <React.Fragment>
        {data && (
          <div className="plan plan--simple">
            <header className="plan__head">
              <h4 className="plan__title">Current Plan: <strong>{data.title}</strong></h4>

              <div className="plan__entry">
                <p><UsdOutput value={data.price} /> / {data.subscription}</p>
              </div>
            </header>

            <div className="plan__body">
              <ul className="plan__list">
                {
                  localIsCancelAtPeriodEnd
                  ? <li>Your subscription will end on - <strong>{moment.unix(nextInvoiceDate).format('MM/DD/YYYY')}</strong></li>
                  : <li>Your next invoice will be on - <strong>{moment.unix(nextInvoiceDate).format('MM/DD/YYYY')}</strong></li>
                }
              </ul>
            </div>

            {!localIsCancelAtPeriodEnd && (
              <footer className="plan__foot">
                <Button onClick={cancelSubscription} rounded>Cancel Subscription</Button>
              </footer>
            )}
          </div>
        )}
      </React.Fragment>
    </LoaderSuspense>
  );
};

export default withFirebase(ProfileCurrentPlan);