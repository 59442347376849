import './styles.scss';

/**
 * The external dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Alert = ({ variant, children, className }) => (
  <div className={classNames('alert', `alert--${variant}`, className)}>{children}</div>
);

/**
 * Define the default props of the component.
 *
 * @type {Object}
 */
Alert.defaultProps = {
  variant: 'primary'
};

/**
 * Define the interface of the component.
 *
 * @type {Object}
 */
Alert.propTypes = {
  variant: PropTypes.string
};

export default Alert;
