/**
 * The internal dependencies.
 */
import FirebaseApp from 'lib/firebase'

/**
 * Class for api.
 *
 * @class Api (name)
 */
class Api extends FirebaseApp {
  /**
   * Constructs the object.
   *
   * @param  {Object} props The properties
   * @return {Void}
   */
  constructor() {
    super()

    this.public_plans_collection = 'public_plans'
    this.users_collection = 'users'
  }

  /**
   * Firebase authorization services.
   */

  /**
   * Firebase signup service.
   *
   * @param  {String} The display name
   * @param  {String} The email
   * @param  {String} The password
   * @return {Promise}
   */
  signup({ displayName, email, password }) {
    return this.firebaseSignup(displayName, email, password)
  }

  /**
   * Firebase login service.
   *
   * @param  {String} The email
   * @param  {String} The password
   * @return {Promise}
   */
  login(email, password) {
    return this.firebaseLogin(email, password)
  }


  /**
   * Firebase logout service.
   *
   * @return {Promise}
   */
  logout() {
    return this.auth.signOut()
  }

  /**
   * Firebase generic services.
   */

  /**
   * Firebase gets the public plans service.
   *
   * @return {Promis}
   */
  getPublicPlans() {
    return new Promise((resolve, reject) => {
      this.database
        .collection(this.public_plans_collection)
        .get()
        .then((snapshot) => {
          const payload = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))

          resolve(payload)
        }).catch(reject)
    })
  }

  /**
   * Firebase gets single public plan service.
   *
   * @return {Promis}
   */
  getSinglePublicPlan(key, id) {
    return new Promise((resolve, reject) => {
      this.database
        .collection(this.public_plans_collection)
        .doc(id)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            reject(new Error('No plan found'))
          } else {
            resolve({ id: doc.id, ...doc.data() })
          }
        }).catch(reject)
    })
  }

  /**
   * Start a server side network request to retrieve stripe customer.
   *
   * @param  {String} The customer id
   * @return {Promise}
   */
  retrievetStripeCustomer(key, customer_id) {
    return this.getStripeCustomer(customer_id)
  }

  /**
   * Facebook api services.
   */

  /**
   * Trigger a server side network request to get the search query data.
   *
   * @param {Object} The params
   */
  requestFbSearchQuery(key, query) {
    return new Promise((resolve, reject) => {
      this.requestGraphApi({
        url: '/search',
        method: 'GET',
        params: {
          type: 'adinterest',
          q: query,
          limit: 10000,
          locale: 'en_US'
        }
      }).then((res) => {
        resolve(res.data.data)
      }).catch(reject)
    })
  }

  /**
   * Trigger a server side network request to get the user facebook ad accounts.
   *
   * @param  {String} facebookToken  The facebook token
   * @return {Promise}
   */
  requestFbUserAdAccounts(key, facebookToken) {
    let userAdsAccounts = []
    let businessAdsAccounts = []



    return new Promise((resolve, reject) => {
      //get the user accounts
      const getUserAdAccounts = this.requestGraphApi({
        accessToken: facebookToken,
        url: '/me/adaccounts',
        method: 'GET',
        params: {
          fields: 'name,account_id'
        }
      })


      //get the bussiness accounts
      const getBusinessAccounts = this.requestGraphApi({
        accessToken: facebookToken,
        url: '/me/business_users',
        method: 'GET'
      })


      //executing the promises
      getUserAdAccounts.then((res) => {
        userAdsAccounts = res.data.data.map(item => ({
          key: item.id,
          value: `${item.name} (${item.account_id})`,
          account_id: item.account_id
        }
        ))

        return getBusinessAccounts
      }).then((res) => {
        const businessAccounts = res.data.data

        //query the ad accounts for business

        const businessAdAccountsPromises = []

        businessAccounts.map(item => {
          //get the bussiness accounts
          const getBusinessAdAccount = this.requestGraphApi({
            accessToken: facebookToken,
            url: `/${item.business.id}/owned_ad_accounts`,
            method: 'GET',
            params: {
              fields: 'name,account_id'
            }
          })

          businessAdAccountsPromises.push(getBusinessAdAccount)
        })

        Promise.all(businessAdAccountsPromises).then((values) => {


          values.map(promiseResponse => {
            promiseResponse.data.data.map(item => (

              businessAdsAccounts.push({
                key: item.id,
                value: `${item.name} (${item.account_id})`,
                account_id: item.account_id
              })

            ))

          })


          //querying the ads accounts for business has completed
          //removing duplicate entries
          const allAdsAccounts = [...userAdsAccounts, ...businessAdsAccounts]

          const removedDuplicates = []
          allAdsAccounts.map((item) => {
            let isAlreadyInserted = false

            removedDuplicates.map(inserted => {
              if (inserted.account_id === item.account_id) {
                isAlreadyInserted = true
              }
            })

            if (!isAlreadyInserted) {
              removedDuplicates.push(item)
            }

          })

          resolve(removedDuplicates)

        });
      }).catch(reject)

    })
  }

  /**
   * Trigger a network request to get an adAccount adSet.
   *
   * @param  {String} facebookToken The facebook token
   * @param  {String} adAccountId   The ad account identifier
   * @return {Promise}
   */
  requestAdSet(key, facebookToken, adAccountId) {
    return new Promise((resolve, reject) => {
      this.requestGraphApi({
        accessToken: facebookToken,
        url: `${adAccountId}/adsets?fields=name,configured_status,effective_status`,
        method: 'GET'
      })
        .then((res) => resolve(res.data.data))
        .catch(reject)
    })
  }


  /**
   * Trigger a network request to get the user facebook active ads.
   *
   * @param  {String} facebookToken The facebook token
   * @param  {String} adAccountId   The ad account identifier
   * @return {Promise}
   */
  requestFbUserActiveAds(key, facebookToken, adAccountId, adSetId) {

    const previews = 'previews.ad_format(MOBILE_FEED_STANDARD)'

    const adSetUrl = `${adSetId}/ads?fields=effective_status,name,campaign_id,${previews},id`
    const activeAdsUrl = `${adAccountId}/ads?fields=effective_status,name,campaign_id,id,${previews}&filtering=[{'field':'effective_status', 'operator':'IN', 'value': ['ACTIVE']}]`

    return new Promise((resolve, reject) => {
      this.requestGraphApi({
        accessToken: facebookToken,
        url: adSetId ? adSetUrl : activeAdsUrl,
        method: 'GET'
      })
        .then((res) => resolve(res.data.data))
        .catch(reject)
    })
  }
}

export default new Api()
