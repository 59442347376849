/**
 * The external dependencies.
 */
import React from 'react'
import NumberFormat from 'react-number-format'

export default (data) => data.map(item => ({
  key: item.id,
  interest_name: item.name,
  audience_size: <NumberFormat thousandSeparator={true} displayType="text" value={item.audience_size} />,
  topic: item.topic,
  path: item.path.join(", ")
}))