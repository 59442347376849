import './styles.scss';

/**
 * The external dependencies.
 */
import React from 'react';
import {Portal} from 'react-portal';

const Modal = ({ children, toggle, open }) => {
  return open && (
    <Portal node={document && document.getElementById('modal-root')}>
      <div className="modal">
        <button
          onClick={toggle}
          className="modal__btn-close"
        >
          <span className="material-icons">close</span>
        </button>

        <div className="modal__content">{children}</div>
      </div>
    </Portal>
  );
};

export default Modal;
