/**
 * The external dependencies.
 */
import {assocPath} from 'ramda';

/**
 * The internal dependencies.
 */
import {
  authSuccess,
  authSetUserClaims,
  authReset
} from 'statecontext/auth/actions';

const initialState = { user: null };

const reducer = (state, action) => {
  switch (action.type) {
    case authSuccess.getType:
      const updatedUser = assocPath(['user'], action.payload, state);
      return updatedUser;

    case authSetUserClaims.getType:
      const updatedClaims = assocPath(['user', 'claims'], action.payload, state);
      return updatedClaims;

    case authReset.getType:
      return {...initialState};
  }

  return state;
};

export default [reducer, initialState];
