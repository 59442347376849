/**
 * The external dependencies.
 */
import {assoc} from 'ramda';

/**
 * The internal dependencies.
 */
import {paymentStatusSet} from 'statecontext/payment-status/actions';

const initialState = {
  data: {
    id: '',
    loading: false,
    status: '',
    type: ''
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case paymentStatusSet.getType:
      const updatedData = assoc('data', action.payload, state);
      return updatedData;
  }

  return state;
};

export default [reducer, initialState];
