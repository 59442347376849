import './styles.scss';

/**
 * The external dependencies.
 */
import React from 'react';
import {isNil} from 'ramda';
import {useQuery} from 'react-query';

/**
 * The internal dependencies.
 */
import Api from 'lib/api';
import withFirebase from 'lib/hocs/with-firebase';
import useStripeSession from 'lib/hooks/use-stripe-session';
import LoaderSuspense from 'components/common/loader-suspense';
import LoaderFull from 'components/common/loader-full';
import LoaderSingle from 'components/common/loader-single';

const supportedCards = {
  'amex': {
    title: 'American Express',
    icon: require('assets/images/amx.png')
  },
  'diners': {
    title: 'DinersClub',
    icon: require('assets/images/diners-club.png')
  },
  'discover': {
    title: 'Discover',
    icon: require('assets/images/discover.png')
  },
  'jcb': {
    title: 'JCB',
    icon: require('assets/images/jcb.png')
  },
  'mastercard': {
    title: 'MasterCard',
    icon: require('assets/images/master-card.png')
  },
  'unionpay': {
    title: 'Union Pay',
    icon: require('assets/images/dollar.png')
  },
  'visa': {
    title: 'Visa',
    icon: require('assets/images/visa.png')
  },
  'unknown': {
    title: 'Payment Card',
    icon: require('assets/images/dollar.png')
  }
};
let currentCardBrand = supportedCards.unknown;

const CardLoader = ({ loading, error, retry, cancel }) => (
  <div className="loader-full">
    {
      error
      ? <h5 className="loader-full__alert">
          <span>Failed to start payment session.</span>

          <a onClick={retry} href="#">Try again</a>
          <a onClick={cancel} href="#">Cancel</a>
        </h5>
      : <LoaderFull />
    }
  </div>
);

const SubscriptionCard = ({ firebase, paymentId, customerId, subscription }) => {
  const { data, status, error } = useQuery(
    paymentId && ['userStripePaymentMethod', paymentId],
    (...props) => Api.getStripePaymentMethod(...props)
  );
  const loading = status === 'loading';
  const {
    startStripeCheckout,
    resetSessionState,
    sessionLoading,
    sessionError
  } = useStripeSession(firebase, {
    method: 'createStripeSessionSetup',
    customer_id: customerId,
    subscription_id: subscription.id
  });

  const retry = event => {
    event.preventDefault();

    startStripeCheckout();
  }

  if (data && !isNil(supportedCards[data.card.brand])) {
    currentCardBrand = supportedCards[data.card.brand];
  }

  return (
    <LoaderSuspense
      loaderComponent={LoaderSingle}
      isLoading={loading}
      hasError={error}
    >
      {(sessionLoading || sessionError) && (
        <CardLoader
          cancel={resetSessionState}
          retry={retry}
          loading={sessionLoading}
          error={sessionError}
        />
      )}

      <div className="item-card">
        {data && (
          <React.Fragment>
            <div className="item-card__group">
              <div className="item-card__icon">
                <img
                  src={currentCardBrand.icon}
                  alt={currentCardBrand.title}
                  width="64"
                  height="40"
                />
              </div>

              <div className="item-card__content">
                <div className="item-card__digits">Ending in {data.card.last4}</div>

                <div className="item-card__text">{currentCardBrand.title}</div>
              </div>
            </div>

            <div className="item-card__group">
              <div className="item-card__exp">
                <div className="item-card__text">Expiration date:</div>
                <div className="item-card__text">{data.card.exp_month} / {data.card.exp_year}</div>
              </div>

              <button
                onClick={startStripeCheckout}
                type="button"
                className="item-card__btn"
              >
                <i className="material-icons">edit</i>
              </button>
            </div>
          </React.Fragment>
        )}
      </div>
    </LoaderSuspense>
  );
};

export default withFirebase(SubscriptionCard);