import 'assets/styles/style.scss';
import './tailwind.output.css';
/**
 * The external dependencies.
 */
import React from 'react';
import ReactDOM from 'react-dom';

/**
 * The internal dependencies.
 */
import App from 'components/app';
import FirebaseApp from 'lib/firebase';
import FirebaseContext from 'lib/firebase/context';

/**
 * The root component.
 *
 * @return {JSX}
 */
const Root = (
  <FirebaseContext.Provider value={new FirebaseApp()}>
    <App />
  </FirebaseContext.Provider>
);

ReactDOM.render(Root, document.getElementById('root'));
