/**
 * The external dependencies.
 */
import React, {Fragment} from 'react';

/**
 * The internal dependencies.
 */
import HeaderAuth from 'components/header/header-auth';

const AuthLayout = ({ children }) => (
  <Fragment>
    <HeaderAuth />

    <main className="main">
      {children}
    </main>
  </Fragment>
);

export default AuthLayout;