import './styles.scss';

/**
 * The external dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * The internal dependencies.
 */
import Alert from 'components/common/alert';
import LoaderSimple from 'components/common/loader-simple';

const InternalLoader = props => (
  <div
    className="loader-suspense"
    {...props}
  >
    <LoaderSimple />
  </div>
);

const InternalError = ({ message }) => !!message
  ? <Alert variant="danger">{message}</Alert>
  : <Alert variant="danger">Something went wrong! <br /> Please try again later.</Alert>;

const LoaderSuspense = ({
  hasError,
  errorComponent = InternalError,
  errorMessage,
  maintainContent,
  isLoading,
  loaderComponent = InternalLoader,
  loaderProps,
  children
}) => {
  const Component = loaderComponent ? loaderComponent : InternalLoader;
  const ErrComponent = errorComponent ? errorComponent : InternalError;

  if (isLoading) return <Component {...loaderProps} />;

  if (hasError && !maintainContent) return <ErrComponent message={errorMessage} />;

  if (hasError && maintainContent) {
    return (
      <React.Fragment>
        <ErrComponent message={errorMessage} />

        {children}
      </React.Fragment>
    );
  }

  return children;
};

/**
 * Define the interface of the component.
 *
 * @type {Object}
 */
LoaderSuspense.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  maintainContent: PropTypes.bool,
  errorMessage: PropTypes.string,
  loaderProps: PropTypes.object,
  children: PropTypes.any.isRequired
};

/**
 * The default props of the component.
 *
 * @type {Object}
 */
LoaderSuspense.defaultProps = {
  loaderProps: {},
  hasError: false,
  maintainContent: false
};

export default LoaderSuspense;
