/**
 * The external dependencies.
 */
import React from 'react'

/**
 * The internal dependencies.
 */
import FirebaseContext from 'lib/firebase/context'

/**
 * Provide firebase instance to the passed component.
 *
 * @param  {Object} The properties
 * @return {React.FC}
 */
const withFirebase = Component => props => (
  <FirebaseContext.Consumer>
    {firebase => (
      <Component
        firebase={firebase}
        {...props}
      />
    )}
  </FirebaseContext.Consumer>
)

export default withFirebase