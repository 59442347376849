/**
 * The external dependencies.
 */
import React from 'react';
import {Route, Redirect} from 'react-router-dom';

/**
 * The internal dependencies.
 */
import {useGlobalState} from 'statecontext/hooks';
import DefaultLayout from 'layouts/default';

const SubscriberRoute = ({ component: Component, ...restProps }) => {
  const {
    getters: {
      isUserLoggedIn,
      subscription
    }
  } = useGlobalState('auth');

  const isSubscribed = (process.env.REACT_APP_BYPASS_PAYMENT ? true :  !!subscription);
  return (
    <Route
      {...restProps}
      render={(routeProps) => {
        if (!isUserLoggedIn) return <Redirect to="/login" />;

        if (isUserLoggedIn && !isSubscribed) return <Redirect to="/subscriptions" />;

        if (
          isUserLoggedIn && isSubscribed && (process.env.REACT_APP_BYPASS_PAYMENT ? false : (subscription.status !== 'active'))
        ) return <Redirect to="/profile" />;

        return (
          <DefaultLayout>
            <Component {...routeProps} />
          </DefaultLayout>
        );
      }}
    />
  );
};

export default SubscriberRoute;