/**
 * The external dependencies.
 */
import React, {useState} from 'react';
import {useQuery} from 'react-query';

/**
 * The internal dependencies.
 */
import Api from 'lib/api';
import FormSearch from 'components/forms/search';
import TableInterests from 'components/tables/interests';

const Home = () => {
  /** The search query initial state */
  const [searchQuery, setSearchQuery] = useState(null);

  /** Search service */
  const searchInfo = useQuery(
    searchQuery && ['searchData', searchQuery],
    (...props) => Api.requestFbSearchQuery(...props)
  );

  return (
    <section className="section">
      <div className="section__group">
        <FormSearch
          loading={searchInfo.status === 'loading'}
          setSearchQuery={setSearchQuery}
        />
      </div>

      <div className="section__group">
        <TableInterests searchInfo={searchInfo} />
      </div>
    </section>
  );
};

export default Home;