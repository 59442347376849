/**
 * The external dependencies.
 */
import React from 'react';
import {Route, Redirect} from 'react-router-dom';

/**
 * The internal dependencies.
 */
import {useGlobalState} from 'statecontext/hooks';
import DefaultLayout from 'layouts/default';

const DashboardRoute = ({ component: Component, ...restProps }) => {
  const {
    getters: {
      isUserLoggedIn,
      subscription
    }
  } = useGlobalState('auth');

  return (
    <Route
      {...restProps}
      render={(routeProps) => {
        if (!isUserLoggedIn) return <Redirect to="/login" />;

        return (
          <DefaultLayout>
            <Component
              {...routeProps}
              isSubscribed={!!subscription}
              subscription={subscription}
            />
          </DefaultLayout>
        )
      }}
    />
  );
};

export default DashboardRoute;